import React from "react"

import Layout from "../modules/layout"
import Seo from "../components/Seo"
import Projects from "../modules/projects"

const PageWork = () => (
  <Layout>
    <Seo
      title="Work"
      description="My Wordpress and WooCommerce developed sites and Node.js HTML5 games."
    />
    <section>
      <h3>My work...</h3>
      <Projects />
    </section>
  </Layout>
)

export default PageWork